import { E_TABLE_INSERT_MODE, E_EVENT_KEY, E_VIEW_MODE, E_TABLE_DELETE_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseDragger = {
  methods: {
    async DoDemoCaseDragger() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '拖拽演示';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml';
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});

        thinkEditor.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
        //or currentEditorsInstance.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);

        thinkEditor.addEventListener(E_EVENT_KEY.DraggerPressed, this.OnDraggerPressed);
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    async OnDocModified(e) {
      let thinkEditor = e.data.editor;
    }
  }
};
