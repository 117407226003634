import { E_EVENT_KEY, E_VIEW_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseRowOwnerAndSign = {
  methods: {
    async DoDemoCaseRowOwnerAndSign() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '行权限+签名';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/功能/权限/行权限+签名.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});
        /*************************************************
         * 配置编辑者信息
         * **********************************************/
        thinkEditor.SetEditorInfo('pc', 'appGuid1', '李护士', 1, {});

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },

    async OnSetEditorInfoTest_1() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetEditorInfo('pc', 'appGuid1', '李护士', 1, {});
    },

    async OnSetEditorInfoTest_2() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetEditorInfo('pc', 'appGuid2', '张护士', 1, {});
    },
    async OnSetRowOwnerAndSignTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.table == undefined) {
        return;
      }

      //let rowsCount = focusProperties.table.rowsCount;
      let colsCount = focusProperties.table.colsCount;
      let rowIdx = focusProperties.table.row.rowIdx;

      //锁定当前行所有者并签名 ownerIsCurrentEditor
      let ret = currentThinkEditor.SetObjectOwner({ object: 'row', rowIdx: rowIdx, ownerIsCurrentEditor: true });
      if (ret) {
        //向 选择行的 最后一个单元格 写入签名数据
        let docProperties = currentThinkEditor.GetDocProperties();
        let currentEditorName = docProperties.editor?.name;
        currentThinkEditor.SetCellContent({
          rowIdx: rowIdx,
          colIdx: colsCount - 1,
          fragment: "<Font fontSizeItem='小五'/>" + currentEditorName
        });
      }
    },
    async OnClearRowOwnerTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.table == undefined) {
        return;
      }
      let rowIdx = focusProperties.table.row.rowIdx;

      //解除当前行编辑者限定 clearOwner
      currentThinkEditor.SetObjectOwner({ object: 'row', rowIdx: rowIdx, clearOwner: true });
    }
  }
};
