<template>
  <div>
    <div class="experiment">
      <div class="bars">
        <vue-file-toolbar-menu v-for="(content, index) in bars_content" :key="'bar-' + index" :content="content" />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus';
import Vue from 'vue';
import VueFileToolbarMenu from 'vue-file-toolbar-menu/src/Bar/Bar.vue';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
import { ThinkEditorDemo } from '@/demo/ThinkEditorDemo';
import { fontsManager, editorLogger, E_DISPLAY_MODE, E_EVENT_KEY, E_PAGE_NUM_SCOPE, E_PAGE_NUM_STYLE, E_PAGE_NUM_FORMAT, E_CHECK_FIGURE_STYLE, E_VIEW_MODE, E_FORMULA_STYLE, E_LAYOUT_VERTICAL_ALIGN } from '@/components/editor/ThinkEditor.Defined';
import { Color2RGBAStr } from '@/components/editor/ThinkEditor.Utils';
// for dynamic css variables
Vue.component('v-style', {
  render(createElement) {
    return createElement('style', this.$slots.default);
  }
});

export default {
  name: 'Demo',
  components: { VueFileToolbarMenu },

  data() {
    return {
      initial_html: 'Powered by ThinkEditor!',

      font: '宋体',
      font_color: 'rgb(0, 0, 0)',
      font_size: 0.4, //单位cm
      theme: 'default',
      edit_mode: true,
      check1: false,
      check2: false,
      check3: true,
      bold_state_: false,
      italic_state_: false,
      strikethrough_state_: false,
      underline_state_: false,
      thinkEditor: undefined,
      thinkEditorDemo: undefined
    };
  },

  computed: {
    // Read the API documentation about the available menu content options

    my_menu_one() {
      return [
        {
          icon: 'format_clear',
          title: '清除格式',
          //hotkey: this.isMacLike ? 'shift+command+l' : 'ctrl+shift+l',
          click: () => this.thinkEditor.ClearFormat()
        },
        {
          icon: 'format_paint',
          title: '格式刷',
          //hotkey: this.isMacLike ? 'shift+command+e' : 'ctrl+shift+e',
          click: () => this.thinkEditor.SetFormatBrush(E_BRUSH_STATE.BrushOnce)
        },
        {
          icon: 'undo',
          title: '恢复',
          //hotkey: this.isMacLike ? 'shift+command+r' : 'ctrl+shift+r',
          click: () => this.thinkEditor.Undo(1)
        },
        {
          icon: 'redo',
          title: '撤销',
          //hotkey: this.isMacLike ? 'shift+command+j' : 'ctrl+shift+j',
          click: () => this.thinkEditor.Redo(1)
        },

        {
          icon: 'file_copy',
          title: '复制',
          //hotkey: this.isMacLike ? 'ctrl+c' : 'ctrl+c',
          click: () => this.thinkEditor.Copy()
        },
        {
          icon: 'content_cut',
          title: '剪切',
          //hotkey: this.isMacLike ? 'ctrl+x' : 'ctrl+x',
          click: () => this.thinkEditor.Cut()
        },
        {
          icon: 'content_copy',
          title: '粘贴',
          //hotkey: this.isMacLike ? 'ctrl+v' : 'ctrl+v',
          click: () => this.thinkEditor.Paste(E_PASTE_TYPE.Normal)
        }
      ];
    },
    my_menu_two() {
      return [
        {
          html: '<div class="ellipsis" style="width: 80px; font-size: 95%;">' + this.font + '</div>',
          title: 'Font',
          chevron: true,
          menu: this.font_menu,
          menu_height: 200,
          click: async () => {
            this.fontNameList = await fontsManager.GetfontsNameList();
            //this.fontNameList = ['宋体', '黑体', '楷体', '仿宋'];
            this.fontNameList.unshift('宋体', '黑体', '楷体', '仿宋');
          }
        },
        { is: 'separator' },
        {
          html: '<div class="ellipsis" style="width: 60px; font-size: 95%;">' + this.font_weight + '</div>',
          title: 'font-weight',
          chevron: true,
          menu: this.font_glyph_menu,
          menu_height: 200,
          menu_width: 100
        },
        { is: 'separator' },
        {
          html: '<div class="ellipsis" style="width: 60px; font-size: 95%;">' + this.fontSizeItem + '</div>',
          title: 'font-size',
          chevron: true,
          menu: this.font_size_menu,
          menu_height: 200,
          menu_width: 100
        },
        { is: 'separator' },
        {
          icon: 'format_bold',
          title: '加粗',
          active: this.bold_state_,
          //hotkey: this.isMacLike ? "command+b" : "ctrl+b",
          click: () => {
            this.bold_state_ = !this.bold_state_;
            this.thinkEditor.SetFontBold(this.bold_state_);
          }
        },
        {
          icon: 'format_italic',
          title: '斜体',
          active: this.italic_state_,
          //hotkey: this.isMacLike ? "command+i" : "ctrl+i",
          click: () => {
            this.italic_state_ = !this.italic_state_;
            this.thinkEditor.SetFontItalic(this.italic_state_);
          }
        },
        {
          icon: 'format_underline',
          title: '下划线',
          active: this.underline_state_,
          //hotkey: this.isMacLike ? "command+u" : "ctrl+u",
          click: () => {
            this.underline_state_ = !this.underline_state_;
            this.thinkEditor.SetFontUnderline(this.underline_state_);
          }
        },
        {
          icon: 'subtitles_off',
          title: '删除线',
          active: this.strikethrough_state_,
          click: () => {
            this.strikethrough_state_ = !this.strikethrough_state_;
            this.thinkEditor.SetFontStrikethrough(this.strikethrough_state_);
          }
        },
        {
          is: fontColorCom,
          title: '字体'
        },
        {
          is: fontBackColorCom,
          title: '设置背景颜色'
        },
        { is: 'separator' },
        {
          icon: 'superscript',
          active: this.script == 1,
          title: '上标',
          click: () => {
            this.thinkEditor.SetFontScript(E_SCRIPT_MODE.SuperScript);
          }
        },
        {
          icon: 'subscript',
          title: '下标',
          active: this.script == 2,
          click: () => {
            this.thinkEditor.SetFontScript(E_SCRIPT_MODE.SubScript);
          }
        },
        {
          icon: 'donut_large',
          title: '文字套圈',
          menu_width: 100,
          menu: [
            {
              text: '无套圈',
              active: this.ferrule == 0,
              click: () => {
                this.thinkEditor.SetFontFerrule(E_FERRULE_MODE.Normal);
              }
            },
            {
              text: '圆形套圈',
              active: this.ferrule == 1,
              click: () => {
                this.thinkEditor.SetFontFerrule(E_FERRULE_MODE.Circle);
              }
            },
            {
              text: '方形套圈',
              active: this.ferrule == 2,
              click: () => {
                this.thinkEditor.SetFontFerrule(E_FERRULE_MODE.Rectangle);
              }
            }
          ]
        },
        { is: 'separator' }
      ];
    },
    my_menu_three() {
      return [
        {
          icon: 'format_list_numbered',
          title: '符号列表',
          chevron: true,
          menu: [
            {
              html: "<span  style='font-size:14px;'>列表选择</span>"
            },
            {
              is: PartMenu
            },
            {
              html: "<span style='font-size:14px;'>样式定义</span>"
            },
            {
              is: PartInput
            }
          ]
        },
        {
          icon: 'format_list_bulleted',
          title: '编号列表',
          click: () => this.thinkEditor.SetParagraphListMode(E_SPECIFIC_INDENT_FORMAT.NoIndent)
        },
        { is: 'separator' },
        {
          icon: 'format_align_left',
          title: '左对齐',
          click: () => this.thinkEditor.SetParagraphAlignContent(E_ALIGN_HORIZONTAL_MODE.Left)
        },
        {
          icon: 'format_align_center',
          title: '居中对齐',
          click: () => this.thinkEditor.SetParagraphAlignContent(E_ALIGN_HORIZONTAL_MODE.Center)
        },
        {
          icon: 'format_align_right',
          title: '右对齐',
          click: () => this.thinkEditor.SetParagraphAlignContent(E_ALIGN_HORIZONTAL_MODE.Right)
        },
        {
          icon: 'format_align_justify',
          title: '两端对齐',
          click: () => this.thinkEditor.SetParagraphAlignContent(E_ALIGN_HORIZONTAL_MODE.Justify)
        },
        {
          icon: 'horizontal_distribute',
          title: '分散对齐',
          click: () => this.thinkEditor.SetParagraphAlignContent(E_ALIGN_HORIZONTAL_MODE.Distribute)
        },
        //{ is: "separator" },
        // { is: DemoCustomButton, text: "Your component", click: () => alert("Your custom action!") },
        { is: 'separator' },
        {
          icon: 'format_clear',
          text: 'Clear',
          title: 'Clear format',
          click: () => this.thinkEditor.ClearFormat()
        },

        { is: 'separator' },
        {
          icon: 'lock',
          title: '内容锁定',
          click: () => this.thinkEditor.SetSelectContentLock(true)
        },
        {
          icon: 'lock_open',
          title: '内容解锁',
          click: () => this.thinkEditor.SetSelectContentLock(false)
        }
      ];
    },
    bars_content() {
      const mac_os_menus =
        this.theme == 'mac-os'
          ? [
              {
                html: '<svg width="16" height="16" viewBox="0 0 16 16" style="fill: var(--bar-font-color, black)" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z"/></svg>',
                menu: [
                  {
                    text: 'Log Out',
                    hotkey: 'shift+command+q',
                    click: () => alert('No way!')
                  }
                ]
              },
              {
                html: '<span style="font-weight:700">YourApp</span>',
                menu: [
                  {
                    text: 'Quit',
                    hotkey: 'command+q',
                    click: () => alert('No way!')
                  }
                ]
              }
            ]
          : [];

      return [
        [
          ...mac_os_menus,
          {
            text: '文件',
            menu: [
              { text: '打开', click: () => this.$emit('docOpt', 'openDoc') /*this.thinkEditorDemo.OpenDoc()*/ },
              {
                text: '新建病历',
                click: () => this.$emit('docOpt', 'newEntityDoc') /*this.thinkEditorDemo.NewEntityDoc()*/
              },
              {
                text: '新建模板',
                click: () => this.$emit('docOpt', 'newTemplateDoc') /*this.thinkEditorDemo.NewTemplateDoc()*/
              },
              {
                text: '新建TimeAxis',
                click: () => this.$emit('docOpt', 'newTimeAxisDoc') /* this.thinkEditorDemo.NewTimeAxis()*/
              },
              { is: 'separator' },
              {
                text: '保存...',
                click: () => this.thinkEditorDemo.SaveToXml()
              },
              {
                text: '保存为模版...',
                click: () => this.thinkEditorDemo.SaveToXmlTemplate()
              },
              {
                text: '保存PDF视图...',
                click: () => this.thinkEditorDemo.SaveToPdfView()
              },
              {
                text: '保存PDF...',
                click: () => this.thinkEditorDemo.SaveToPdf()
              },
              {
                text: '保存HTML...',
                click: () => this.thinkEdiGitLenstorDemo.SaveToHtml()
              },
              { is: 'separator' },
              {
                text: '打印...',
                click: () => this.thinkEditorDemo.PrintDoc()
              }
              // { is: "separator" },
              //{ text: "关闭",  click () {if (confirm("Do you want to close the page?")) window.close(); },
              //},
            ]
          },
          /*{
            text: "Edit",
            menu: [web版复制粘贴要走系统事件
              { text: "Cut", click: () => this.thinkEditor.Cut() },
              { text: "Copy", click: () => this.thinkEditor.Copy() },
              { text: "Paste", click: () => this.thinkEditor.Paste(E_PASTE_TYPE.Normal) },
            ],
          },*/
          {
            text: '插入',
            menu: [
              {
                text: '元素',
                click: () => this.thinkEditorDemo.InsertElement()
              },
              {
                text: '选框',
                click: () => this.thinkEditor.InsertCheckBox({ id: '', weight: 0, content: '选框示例', checkFigureStyle: E_CHECK_FIGURE_STYLE.STYLE_1 })
              },
              {
                text: '公式',
                click: () => this.thinkEditor.InsertFormula({ id: '', style: E_FORMULA_STYLE.MensesStyle_1, alignVerticalLayout: E_LAYOUT_VERTICAL_ALIGN.OtherMiddle })
              },
              {
                text: '表格',
                click: () => this.thinkEditor.InsertTable({ rowsCount: 3, colsCount: 3 })
              },
              { text: '图像', click: () => this.thinkEditorDemo.InsertImage() },
              {
                text: '选择图像',
                click: () => this.thinkEditorDemo.SelectImage()
              },
              {
                text: '页码',
                click: () => this.thinkEditor.InsertPageNum({ scope: E_PAGE_NUM_SCOPE.WholeDocnment, style: E_PAGE_NUM_STYLE.OrderListModel_0, format: E_PAGE_NUM_FORMAT.PageOrderPagesCountFormat })
              },
              {
                text: '二维码',
                click: () => this.thinkEditorDemo.InsertQRCode()
              },
              {
                text: '条形码',
                click: () => this.thinkEditorDemo.InsertBarCode()
              },
              {
                text: '特殊符号',
                icon: 'emoji_symbols',
                click: () => this.thinkEditor.DisplaySpecialSymbolSetWindow(E_DISPLAY_MODE.Show)
              },
              {
                text: '换行符',
                click: () => this.thinkEditor.InsertLineFeed(1)
              },
              {
                text: '分页符',
                click: () => this.thinkEditor.InsertPageFeed(1)
              },
              {
                text: '分隔符',
                icon: 'horizontal_rule',
                click: () => this.thinkEditor.DisplaySeparatorSetWindow(E_DISPLAY_MODE.Show) //or 直接调用接口InsertSeparator
              }
            ]
          },
          {
            text: '功能',
            menu: [
              { text: 'test1', click: () => this.thinkEditorDemo.Test1() },
              { text: 'test2', click: () => this.thinkEditorDemo.Test2() },
              {
                text: '关闭当前文档',
                click: () => this.$emit('closeDocEditor')
              },
              {
                text: '关闭所有文档',
                click: () => this.$emit('closeAllDocEditor')
              },
              {
                text: '撤销签名',
                click: () => this.thinkEditorDemo.CancelSign()
              },
              {
                text: '插入模板片段',
                click: () => this.thinkEditorDemo.InsertFragment('HunFouFragment.xml')
              },
              {
                text: '追加模板片段',
                click: () => this.thinkEditorDemo.AppendFragment('BingChengFragment.xml')
              } /*,
              {
                text: '自定义菜单',
                click: () => this.thinkEditorDemo.SetUserDefinedMenuDemo()
              }*/
            ]
          },
          {
            text: '新实例',
            menu: [
              {
                text: '新增实例',
                click: () => {
                  // 触发父组件创建编辑器实例
                  this.$emit('addEditorInstance');
                  // EventBus.$emit("EditorOpt", "add")
                }
              },
              {
                text: '释放实例',
                click: () => {
                  this.$emit('removeEditorInstance');
                  // EventBus.$emit("EditorOpt", "del")
                }
              } /*,
              {
                text: "弹出实例",
                click: () => this.thinkEditorDemo.PopEditor()
              }*/
            ]
          },
          {
            text: '打印',
            menu: [
              {
                text: '自定义打印',
                icon: 'print',
                click: () => this.thinkEditor.DisplayPrintSetWindow(E_DISPLAY_MODE.Show, { view: { mode: E_VIEW_MODE.Print } })
              }
            ]
          },
          {
            text: '痕迹',
            menu: [
              {
                text: '显痕编辑',
                click: () => this.thinkEditorDemo.SetViewModeShowReviseEdit(this.thinkEditor)
              },
              {
                text: '隐痕编辑',
                click: () => this.thinkEditorDemo.SetViewModeHideReviseEdit(this.thinkEditor)
              },
              {
                text: '无痕编辑',
                click: () => this.thinkEditorDemo.SetViewModeNullReviseEdit(this.thinkEditor)
              }
            ]
          },
          {
            text: 'Help',
            menu: [
              {
                text: 'About',
                icon: 'help',
                click: () => alert('成都屹爱信息科技-信译(ThinkEditor)全结构化电子病历编辑器领航者！')
              },
              { is: 'separator' },
              {
                text: 'API',
                icon: 'exit_to_app',
                click: () => window.open('http://thinkeditor.com:8181/docs/thinkeditor-manual-v2/enter')
              }
            ],
            menu_width: 220
          }
          /*
          { is: "spacer" },
          {
            icon: this.edit_mode ? "lock_open" : "lock",
            title: "Toggle edit mode",
            active: !this.edit_mode,
            click: () => {
              this.edit_mode = !this.edit_mode;
            },
          },*/
        ]
      ];
    },
    isMacLike: () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
    font_menu() {
      return this.font_list.map(font => {
        return {
          html: '<span class="ellipsis" style="font-family:' + font + '">' + font + '</span>',
          icon: this.theme != 'default' && this.font == font ? 'check' : false,
          active: this.font == font,
          height: 20,
          click: () => {
            this.thinkEditor.SetFontType(font);
            this.font = font;
          }
        };
      });
    },
    font_list: () => ['宋体', '楷体', '黑体', '仿宋', 'Garamond', 'Arial', 'Avenir', 'Courier New', 'Garamond', 'Georgia', 'Impact', 'Helvetica', 'Palatino', 'Roboto', 'Times New Roman', 'Verdana'],
    is_touch_device: () => 'ontouchstart' in window || window.navigator.msMaxTouchPoints > 0
  },

  methods: {
    focus_text() {
      // this.$refs.text.focus();
      document.execCommand('selectAll', false, null);
      document.getSelection().collapseToEnd();
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
      this.thinkEditorDemo = new ThinkEditorDemo(this.thinkEditor);
    },
    OnFontFormatChange(e) {
      editorLogger.LogEvent(e);
      let data = e.data.data;
      //console.log(data);

      this.bold_state_ = data.bold;
      this.italic_state_ = data.italic;
      this.underline_state_ = data.underline;
      this.strikethrough_state_ = data.strikethrough;
      this.font_color = Color2RGBAStr(data.color);
      this.font = data.fontName;
      this.font_size = parseFloat(data.size);
    },
    EventTest(e) {
      editorLogger.LogEvent(e);
    }
  },

  mounted() {
    // if (!this.is_touch_device) this.focus_text();
    //var canvas = document.getElementById("canvas");
    /*************************
     * 方案一：监听所有事件再分发
     * ***********************/
    //  var thinkEdtior = canvas.thinkEdtior;
    //ThinkEditor.Init(canvas);
    // thinkEdtior.RegisterEventListener(this.EventListener);//监听所有事件
    /*************************
     * 方案二：监听特定事件
     * ***********************/
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    editorsInstanceManager.addEventListener(E_EVENT_KEY.fontFormat, this.OnFontFormatChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();

    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  }
};
</script>

<style>
:root {
  --demo-font-color: rgb(74, 238, 164);
}
html {
  height: 100%;
  overflow-y: scroll;
  /* background-color: rgb(74, 238, 164); */
  transition-delay: 0.5s;
}
body {
  margin: 0;
  min-height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--demo-font-color);
  /* background-color: rgb(36, 44, 41); */
  /* box-shadow: inset 0 0 30vw 15vw black; */
  transition: color 0.3s, background-color 0.3s, box-shadow 0.5s;
}
::selection {
  background-color: rgba(74, 238, 164, 0.2);
}
</style>

<style scoped>
a {
  color: inherit;
}
svg.github {
  fill: var(--demo-font-color);
  margin-right: 5px;
}
*[contenteditable='true'] {
  outline: none;
}
select {
  outline: none;
}

.title {
  text-align: center;
  font-size: 50px;
  /* padding-top: 30px; */
}
.subtitle {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}
.experiment {
  width: 100%;
  height: 150px;
  margin: auto;
  max-width: 1150px;
}
.bars {
  background-color: var(--demo-bars-bkg, white);
  border-radius: var(--demo-bars-border-radius, 5px);
  /* box-shadow: var(--demo-bars-shadow, 0 0 20px black); */
  padding: var(--demo-bars-padding, 5px);
  transition: 0.5s;
}
::v-deep.bars * {
  transition: font-size 0.1s linear, padding 0.1s linear, margin 0.1s linear;
}
.styles {
  position: fixed;
  /* top: 10px; */
  right: 10px;
}
.text {
  font-family: var(--bar-font-family);
  width: 90%;
  margin: 30px auto;
  font-size: 20px;
  min-height: 250px;
  background-color: var(--demo-text-bkg-color);
  background-image: var(--demo-text-bkg-img, url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFFFFF30' stroke-width='2' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"));
  border: var(--demo-text-border);
  box-shadow: var(--demo-text-box-shadow);
  padding: 10px 15px;
  transition: 0.5s;
}
</style>
